import { getByTestId } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/id";
import logoEdhos from "assets/img/logo.png";
import CardBed from "components/CardBed";
import { get } from "utils/request";

function Bed() {
  const [dayOfWeekIso, setDayOfWeekIso] = useState(new Date().getDay());
  const [currentDateTime, setCurrentDateTime] = useState(
    new Date().toISOString()
  );

  const [hotelClass, setHotelClass] = useState([]);
  const [totalBed, setTotalBed] = useState(0);

  useEffect(() => {
    // getBedClass();
    const interval = setInterval(() => {
      initBed();
    }, 60000 * 10); // 10 minutes

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    initBed();
  }, [dayOfWeekIso]);

  const initBed = async () => {
    const { data } = await getBedRoom();
    const { data: classRoom } = await getDashboardBed();

    setHotelClass([]);
    setTotalBed(data.length);

    setHotelClass(classRoom);
  };

  const getBedRoom = () => {
    return new Promise((resolve, reject) => {
      get(`/get-hotel-bed`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const getDashboardBed = () => {
    return new Promise((resolve, reject) => {
      get(`/get-dashboard-bed`)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const isChangeDaysOfWeekIso = (newValue) => {
    setDayOfWeekIso((prev) => {
      if (prev !== newValue) {
        // getDataJadwal(newValue);
        return newValue;
      } else {
        return prev;
      }
    });
  };

  const getDataClassRoom = (className) => {
    const data = hotelClass.find((x) => x.class === className);

    if (data === undefined) {
      return {
        class: className,
        kosong: 0,
        total: 0,
        terisi: 0,
      };
    }

    return {
      ...data,
      kosong: Number(data.kosong),
      total: Number(data.total),
      terisi: Number(data.terisi),
    };
  };

  if (hotelClass.length === 0) {
    return <p>Loading</p>;
  }

  return (
    <div className='relative h-screen'>
      <img
        src={logoEdhos}
        alt='Logo'
        className='w-[15vh] absolute top-4 left-4'
      />

      <h1 className='text-center font-extrabold text-[4vh] text-gradient uppercase'>
        Ketersediaan Rawat Inap (KELAS)
      </h1>
      <h3 className='text-center font-extrabold text-[2.5vh] text-gradient'>
        <Moment
          interval={1000}
          format='dddd, DD MMM HH:mm:ss'
          locale='id'
          onChange={(val) => setCurrentDateTime(new Date().toISOString())}
        >
          {currentDateTime}
        </Moment>{" "}
        WIB
        <Moment
          className='hidden'
          interval={1000}
          format='E'
          // format='dddd, DD MMM HH:mm:ss'
          locale='id'
          onChange={(val) => isChangeDaysOfWeekIso(Number(val))}
        >
          {new Date().toISOString()}
        </Moment>
      </h3>

      <div className='flex flex-col'>
        <div className='flex gap-[2vh] p-[2vh]'>
          <div className='rounded-lg bg-white shadow-lg w-[100%] h-[20vh] flex justify-center items-center'>
            <div className='text-center'>
              <div className='text-[12vh] text-gray-700 font-extrabold leading-[13vh]'>
                {totalBed}
              </div>
              <div className='text-[3vh] text-gray-700/80 font-bold'>
                Total Bed
              </div>
            </div>
          </div>
          <div className='flex gap-[2vh] w-[100%] h-[20vh]'>
            <CardBed
              title='SUITE'
              countClass={
                getDataClassRoom("SUITE").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("SUITE").kosong === 0
                  ? "Full"
                  : getDataClassRoom("SUITE").kosong
              }
              label={`Total : ${getDataClassRoom("SUITE").total}`}
            />
          </div>
        </div>

        <div className='flex flex-col'>
          <div className='grid grid-cols-5 w-full gap-[2vh] px-[2vh] pb-[2vh]'>
            <CardBed
              title='JUNIOR SUITE'
              countClass={
                getDataClassRoom("JUNIOR SUITE").kosong === 0
                  ? "text-[7vh]"
                  : null
              }
              count={
                getDataClassRoom("JUNIOR SUITE").kosong === 0
                  ? "Full"
                  : getDataClassRoom("JUNIOR SUITE").kosong
              }
              label={`Total : ${getDataClassRoom("JUNIOR SUITE").total}`}
            />
            <CardBed
              title='VIP A'
              countClass={
                getDataClassRoom("VIP A").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("VIP A").kosong === 0
                  ? "Full"
                  : getDataClassRoom("VIP A").kosong
              }
              label={`Total : ${getDataClassRoom("VIP A").total}`}
            />
            <CardBed
              title='VIP B'
              countClass={
                getDataClassRoom("VIP B").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("VIP B").kosong === 0
                  ? "Full"
                  : getDataClassRoom("VIP B").kosong
              }
              label={`Total : ${getDataClassRoom("VIP B").total}`}
            />
            <CardBed
              title='VIP C'
              countClass={
                getDataClassRoom("VIP C").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("VIP C").kosong === 0
                  ? "Full"
                  : getDataClassRoom("VIP C").kosong
              }
              label={`Total : ${getDataClassRoom("VIP C").total}`}
            />
            <CardBed
              title='VIP D'
              countClass={
                getDataClassRoom("VIP D").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("VIP D").kosong === 0
                  ? "Full"
                  : getDataClassRoom("VIP D").kosong
              }
              label={`Total : ${getDataClassRoom("VIP D").total}`}
            />
            <CardBed
              title='KELAS 1'
              countClass={
                getDataClassRoom("KELAS 1").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("KELAS 1").kosong === 0
                  ? "Full"
                  : getDataClassRoom("KELAS 1").kosong
              }
              label={`Total : ${getDataClassRoom("KELAS 1").total}`}
            />
            <CardBed
              title='KELAS 2'
              countClass={
                getDataClassRoom("KELAS 2").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("KELAS 2").kosong === 0
                  ? "Full"
                  : getDataClassRoom("KELAS 2").kosong
              }
              label={`Total : ${getDataClassRoom("KELAS 2").total}`}
            />
            <CardBed
              title='KELAS 3'
              countClass={
                getDataClassRoom("KELAS 3").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("KELAS 3").kosong === 0
                  ? "Full"
                  : getDataClassRoom("KELAS 3").kosong
              }
              label={`Total : ${getDataClassRoom("KELAS 3").total}`}
            />
            <CardBed
              title='RG BAYI'
              countClass={
                getDataClassRoom("RG BAYI ( SUITE )").kosong +
                  getDataClassRoom("RG BAYI ( NON SUITE )").kosong ===
                  0
                  ? "text-[4vh]"
                  : null
              }
              count={
                getDataClassRoom("RG BAYI ( SUITE )").kosong +
                  getDataClassRoom("RG BAYI ( NON SUITE )").kosong ===
                  0
                  ? "Full"
                  : getDataClassRoom("RG BAYI ( SUITE )").kosong +
                  getDataClassRoom("RG BAYI ( NON SUITE )").kosong
              }
              label={`Total : ${getDataClassRoom("RG BAYI ( SUITE )").total +
                getDataClassRoom("RG BAYI ( NON SUITE )").total
                }`}
            />
            <CardBed
              title='PERINATOLOGI'
              countClass={
                getDataClassRoom("PERINATOLOGI").kosong === 0
                  ? "text-[7vh]"
                  : null
              }
              count={
                getDataClassRoom("PERINATOLOGI").kosong === 0
                  ? "Full"
                  : getDataClassRoom("PERINATOLOGI").kosong
              }
              label={`Total : ${getDataClassRoom("PERINATOLOGI").total}`}
            />
            <CardBed
              title='ICU'
              countClass={
                getDataClassRoom("ICU").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("ICU").kosong === 0
                  ? "Full"
                  : getDataClassRoom("ICU").kosong
              }
              label={`Total : ${getDataClassRoom("ICU").total}`}
            />
            <CardBed
              title='HCU'
              countClass={
                getDataClassRoom("HCU").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("HCU").kosong === 0
                  ? "Full"
                  : getDataClassRoom("HCU").kosong
              }
              label={`Total : ${getDataClassRoom("HCU").total}`}
            />
            <CardBed
              title='PICU'
              countClass={
                getDataClassRoom("PICU").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("PICU").kosong === 0
                  ? "Full"
                  : getDataClassRoom("PICU").kosong
              }
              label={`Total : ${getDataClassRoom("PICU").total}`}
            />
            <CardBed
              title='NICU'
              countClass={
                getDataClassRoom("NICU").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("NICU").kosong === 0
                  ? "Full"
                  : getDataClassRoom("NICU").kosong
              }
              label={`Total : ${getDataClassRoom("NICU").total}`}
            />
            <CardBed
              title='ISOLASI'
              countClass={
                getDataClassRoom("ISOLASI").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("ISOLASI").kosong === 0
                  ? "Full"
                  : getDataClassRoom("ISOLASI").kosong
              }
              label={`Total : ${getDataClassRoom("ISOLASI").total}`}
            />
            {/* <CardBed
              title='HEALING'
              countClass={
                getDataClassRoom("KELAS 1 HEALING").kosong +
                  getDataClassRoom("SUITE HEALING").kosong +
                  getDataClassRoom("VIP A HEALING").kosong +
                  getDataClassRoom("VIP B HEALING").kosong ===
                0
                  ? "text-[4vh]"
                  : null
              }
              count={
                getDataClassRoom("KELAS 1 HEALING").kosong +
                  getDataClassRoom("SUITE HEALING").kosong +
                  getDataClassRoom("VIP A HEALING").kosong +
                  getDataClassRoom("VIP B HEALING").kosong ===
                0
                  ? "Full"
                  : getDataClassRoom("KELAS 1 HEALING").kosong +
                    getDataClassRoom("SUITE HEALING").kosong +
                    getDataClassRoom("VIP A HEALING").kosong +
                    getDataClassRoom("VIP B HEALING").kosong
              }
              // label={`Total : ${
                getDataClassRoom("KELAS 1 HEALING").total +
                getDataClassRoom("SUITE HEALING").total +
                getDataClassRoom("VIP A HEALING").total +
                getDataClassRoom("VIP B HEALING").total
              }`}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bed;
