import { getByTestId } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/id";
import logoEdhos from "assets/img/logo.png";
import CardBed from "components/CardBed";
import { get } from "utils/request";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function BedJkn() {
  const navigate = useNavigate();
  const [dayOfWeekIso, setDayOfWeekIso] = useState(new Date().getDay());
  const [currentDateTime, setCurrentDateTime] = useState(
    new Date().toISOString()
  );

  const [hotelClass, setHotelClass] = useState([]);
  const [totalBed, setTotalBed] = useState(0);

  useEffect(() => {
    // getBedClass();
    const interval = setInterval(() => {
      initBed();
    }, 60000 * 10); // 10 minutes

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    initBed();
  }, [dayOfWeekIso]);

  const initBed = async () => {
    // const { data } = await getBedRoom();
    const { data: classRoom } = await getDashboardBed();

    setHotelClass([]);
    setTotalBed(8);

    setHotelClass(classRoom);
    console.log('classRoom', classRoom)
  };

  const getDashboardBed = () => {
    return axios.get(`${process.env.REACT_APP_FLOW_EDELWEISS_BASE_URL}/webhook/get-bed-jkn`, {
      auth: {
        username: process.env.REACT_APP_FLOW_EDELWEISS_USERNAME,
        password: process.env.REACT_APP_FLOW_EDELWEISS_PASSWORD
      }
    })
  };

  const isChangeDaysOfWeekIso = (newValue) => {
    setDayOfWeekIso((prev) => {
      if (prev !== newValue) {
        // getDataJadwal(newValue);
        return newValue;
      } else {
        return prev;
      }
    });
  };

  if (hotelClass.length === 0) {
    return <p>Loading</p>;
  }

  return (
    <div className='relative h-screen'>
      <img
        src={logoEdhos}
        alt='Logo'
        className='w-[15vh] absolute top-4 left-4'
        onClick={() => { navigate('/') }}
      />

      <h1 className='text-center font-extrabold text-[4vh] text-gradient uppercase'>
        Ketersediaan Ruangan
      </h1>
      <h3 className='text-center font-extrabold text-[2.5vh] text-gradient'>
        <Moment
          interval={1000}
          format='dddd, DD MMM HH:mm:ss'
          locale='id'
          onChange={(val) => setCurrentDateTime(new Date().toISOString())}
        >
          {currentDateTime}
        </Moment>{" "}
        WIB
        <Moment
          className='hidden'
          interval={1000}
          format='E'
          // format='dddd, DD MMM HH:mm:ss'
          locale='id'
          onChange={(val) => isChangeDaysOfWeekIso(Number(val))}
        >
          {new Date().toISOString()}
        </Moment>
      </h3>

      <div className='flex flex-col pt-[2vh]'>
        {/* <div className='flex gap-[2vh] p-[2vh]'>
          <div className='rounded-lg bg-white shadow-lg w-[100%] h-[20vh] flex justify-center items-center'>
            <div className='text-center'>
              <div className='text-[12vh] text-gray-700 font-extrabold leading-[13vh]'>
                {totalBed}
              </div>
              <div className='text-[3vh] text-gray-700/80 font-bold'>
                Total Bed
              </div>
            </div>
          </div>
          <div className='flex gap-[2vh] w-[100%] h-[20vh]'>
            <CardBed
              title='SUITE'
              countClass={
                getDataClassRoom("SUITE").kosong === 0 ? "text-[7vh]" : null
              }
              count={
                getDataClassRoom("SUITE").kosong === 0
                  ? "Full"
                  : getDataClassRoom("SUITE").kosong
              }
              label={`Total : ${getDataClassRoom("SUITE").total}`}
            />
          </div>
        </div> */}

        <div className='flex flex-col'>
          <div className='grid grid-cols-4 w-full gap-[2vh] px-[2vh] pb-[2vh]'>
            {hotelClass.map((item, index) => {
              return (
                <CardBed
                  key={item.id}
                  title={item.kodekelas}
                  count={item.tersedia}
                // label={`Total : ${item.kapasitas}`}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BedJkn;
