// import React from 'react'
// import { CheckCircleIcon } from '@heroicons/react/24/outline'

// function RadioButton(props) {
//   return (
//     <li className='relative'>
//       <input type="radio" className="hidden peer" {...props} />
//       <label htmlFor={props.id} className={'px-5 py-3 block bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primary peer-checked:ring-1 peer-checked:border-transparent ' + props.className}>
//         <h4 className='font-semibold'>{props.label}</h4>
//         {props.description && <p className='text-sm opacity-70'>{props.description}</p>}
//       </label>
//       <div className='absolute inset-y-0 flex-col justify-center hidden peer-checked:flex right-3'>
//         <CheckCircleIcon className='w-6 h-6 text-primary' />
//       </div>
//     </li>
//   )
// }

// export default RadioButton
// import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { TicketIcon, CheckCircleIcon } from '@heroicons/react/24/solid'

function CheckboxCard(props) {
  return (
    <li className='relative'>
      <input type="checkbox" className="hidden peer" {...props} />
      <label htmlFor={props.disabled ? '#' : props.id} className={'overflow-hidden relative px-5 py-3 block bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primary peer-checked:ring-1 peer-checked:border-transparent ' + props.className}>
        <TicketIcon className='absolute -top-1 w-24 h-20 rotate-[125deg] right-3 text-gray-300' stroke={1.2} />
        <TicketIcon className='absolute top-0 w-16 h-16 rotate-[65deg] right-24 text-gray-200' stroke={1.2} />
        <h4 className='font-semibold'>{props.label}</h4>
        {props.description && <p className='text-sm opacity-70'>{props.description}</p>}
      </label>
      <div className='absolute inset-y-0 flex-col justify-center hidden peer-checked:flex right-3'>
        <CheckCircleIcon className='w-6 h-6 text-primary' />
      </div>
    </li>
  )
}

export default CheckboxCard
