import React from 'react'

const StateFooterQueue = ({ labelRight = 0, labelLeft = 'TOTAL', classBg = 'bg-accent/20 text-accent' }) => {
  return (
    <div className='absolute inset-x-0 bottom-0 bg-white'>
      <div className={`flex justify-between w-full px-5 py-1 ${classBg}`}>
        <h5 className='text-xl font-bold'>{labelLeft}</h5>
        <h5 className='text-xl font-bold'>{labelRight}</h5>
      </div>
    </div>
  )
}

export default StateFooterQueue