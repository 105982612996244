import CardMenu from 'components/CardMenu'
import CardMenuKiosk from 'components/CardMenuKiosk'
import { storeTokenNocoDbInLocalStorage } from 'lib/common'
import React, { useEffect } from 'react'
import { useApiNocoDb } from 'services'

function Home() {

  useEffect(() => {

    // const autoLoginNocoDb = async () => {
    //   const { data: responseNocoDb } = await useApiNocoDb.post('/auth/user/signin', {
    //     email: process.env.REACT_APP_NOCODE_EMAIL,
    //     password: process.env.REACT_APP_NOCODE_PASSWORD
    //   })

    //   storeTokenNocoDbInLocalStorage(responseNocoDb.token);
    // }

    // autoLoginNocoDb()

  }, [])

  return (
    <>
      <div className='max-w-3xl py-16 mx-auto'>
        <h1 className='text-3xl font-bold text-center'>Menu</h1>
        <h5 className='text-sm text-center opacity-70'>Pilih menu yang ingin anda akses</h5>
        <div className='mt-12'>
          <CardMenu />
        </div>
        <div className="my-12 text-xl font-bold divider">KIOSK</div>
        <div>
          <CardMenuKiosk />
        </div>
      </div>
    </>
  )
}

export default Home