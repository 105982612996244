import { ArrowRightOnRectangleIcon, BeakerIcon, BuildingOfficeIcon, ClipboardDocumentCheckIcon, ComputerDesktopIcon, DocumentArrowUpIcon, HeartIcon, InboxStackIcon, ScissorsIcon, TicketIcon, UserIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ItemMenu from './ItemMenu'
import useUser from 'hooks/useUser'
import { removeTokenFromLocalStorage } from 'lib/common'

function CardMenu() {
  const navigate = useNavigate()
  const { user, authenticated } = useUser();

  const dataMenu = [
    {
      title: 'Jadwal Dokter',
      description: 'Plasma Jadwal Dokter',
      icon: <ComputerDesktopIcon className='w-6 h-6' />,
      onClick: () => navigate('/jadwal/dokter')
    },
    {
      title: 'Ruangan',
      description: 'Ketersediaan Bed',
      icon: <BuildingOfficeIcon className='w-6 h-6' />,
      className: 'bg-teal-500/10 text-teal-500',
      onClick: () => navigate('/bed')
    },
    {
      title: 'Ruangan JKN',
      description: 'Ketersediaan Bed JKN',
      icon: <BuildingOfficeIcon className='w-6 h-6' />,
      className: 'bg-teal-500/10 text-teal-500',
      onClick: () => navigate('/bed-jkn')
    },
    {
      title: 'Plasma Farmasi',
      description: 'Plasma Antrian Farmasi',
      icon: <BeakerIcon className='w-6 h-6' />,
      className: 'bg-yellow-500/10 text-yellow-500',
      onClick: () => navigate('/plasma-farmasi')
    },
    {
      title: 'Plasma MnC',
      description: 'Antrian Farmasi (MnC)',
      icon: <HeartIcon className='w-6 h-6' />,
      className: 'bg-accent/10 text-accent',
      onClick: () => navigate('/plasma-farmasi/mnc')
    },
    {
      title: 'Update Farmasi',
      description: 'Update Status Plasma',
      icon: <ClipboardDocumentCheckIcon className='w-6 h-6' />,
      className: 'bg-violet-600/10 text-violet-600',
      onClick: () => navigate('/plasma-farmasi/update')
    },
    {
      title: 'Plasma Operasi',
      description: 'Operasi Hari ini',
      icon: <ScissorsIcon className='w-6 h-6' />,
      className: 'bg-orange-600/10 text-orange-600',
      onClick: () => navigate('/jadwal/operasi')
    },
    {
      title: 'Gizi VIP',
      description: 'Order dari ranap VIP',
      icon: <TicketIcon className='w-6 h-6' />,
      className: 'bg-blue-600/10 text-blue-600',
      onClick: () => navigate('/gizi/list-order')
    },
    {
      title: 'Merchant',
      description: 'Scan Voucher',
      icon: <TicketIcon className='w-6 h-6' />,
      className: 'bg-green-600/10 text-green-600',
      onClick: () => navigate('/merchant/voucher/update')
    },
    {
      title: 'Invoice',
      description: 'Send invoice to customer',
      icon: <DocumentArrowUpIcon className='w-6 h-6' />,
      onClick: () => navigate('/invoice/send')
    },
  ]

  const onClickLogout = async () => {
    removeTokenFromLocalStorage();

    window.location.href = '/signin';
  }

  if (authenticated) {
    dataMenu.unshift({
      title: `Hi, ${user.name_real}`,
      description: 'Klik untuk Logout / Ganti Akun',
      icon: <ArrowRightOnRectangleIcon className='w-6 h-6' />,
      className: 'bg-red-500 text-white',
      onClick: onClickLogout
    })
  }

  if (!user || !authenticated) {
    dataMenu.unshift(
      {
        title: 'Sign In',
        description: 'Masuk dengan akun Tera',
        icon: <UserIcon className='w-6 h-6' />,
        className: 'bg-red-500 text-white',
        onClick: () => navigate('/signin')
      },
    )
  }

  return (
    <div className='grid grid-cols-1 gap-6 px-6 sm:px-0 sm:grid-cols-2'>
      {dataMenu.map(item => {
        return <ItemMenu key={item.title} {...item} />
      })}
    </div>
  )
}

export default CardMenu