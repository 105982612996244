import React, { useEffect, useState } from 'react'
import logoEdhos from "assets/img/logo.png";
import Moment from 'react-moment';
import socket from 'lib/socket';
import { useApiBridge } from 'services';
import ClockFullTime from 'components/ClockFullTime';
import StateQueue from './components/StateQueue';
import { poli_mnc } from 'constants';
import { useNavigate } from 'react-router-dom';
import useUser from 'hooks/useUser';

function PlasmaFarmasi() {
  const navigate = useNavigate();
  // const { user, authenticated } = useUser();
  // if (!user || !authenticated) {
  //   navigate('/signin?callback=/plasma-farmasi')
  // }
  const [isConnected, setIsConnected] = useState(false);

  const [dataPlasma, setDataPlasma] = useState([])

  useEffect(() => {

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function receive_message(value) {
      const data = JSON.parse(value);

      // if (poli_mnc.includes(data.poli_id)) return; // jika dari poli mnc maka tidak di tampilkan

      setDataPlasma((prev) => {
        const filterId = prev.filter((item) => item.Id !== data.Id)
        const newData = [...filterId, data];
        return newData;
      })
    }

    function receive_message_delete(value) {
      const data = JSON.parse(value)

      // if (poli_mnc.includes(data.poli_id)) return; // jika dari poli mnc maka tidak di lanjutkan

      // setDataPlasma((prev) => {
      //   const filterId = prev.filter((item) => item.Id !== data.Id && item.discharge_date === null)
      //   return filterId;
      // })
      setDataPlasma([])
      getDataWaiting()
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('plasma/farmasi', receive_message);
    socket.on('plasma/farmasi/delete', receive_message_delete);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('plasma/farmasi', onDisconnect);
      socket.off('plasma/farmasi/delete', onDisconnect);
    };
  }, [socket])

  useEffect(() => {
    getDataWaiting()

    const interval = setInterval(() => {
      getDataWaiting()
    }, 60000 * 10); // 10 minutes

    return () => {
      clearInterval(interval);
    };
  }, []);

  async function getDataWaiting() {
    const { data: dataWaiting } = await useApiBridge.get(`/farmasi`)

    const executive = dataWaiting.data.filter(item => !poli_mnc.includes(+item.poli_id)) // filter poli executive only

    setDataPlasma(executive)
  }

  return (

    <div className='relative h-screen flex flex-col'>
      <div className='flex justify-between px-5 pt-8 pb-5'>
        <img
          src={logoEdhos}
          alt='Logo'
          className='w-32 z-10 cursor-pointer'
          onClick={() => { navigate('/') }}
        />

        <h3 className='absolute inset-x-0 top-5 text-center font-extrabold text-4xl text-primary uppercase'>
          Plasma Farmasi Eksekutif
        </h3>

        <h3 className='text-center font-extrabold text-xl text-primary z-10'>
          <ClockFullTime /><span className='text-sm ml-4 cursor-pointer' onClick={() => window.location.reload()}>{isConnected ? '🟢' : '🔴'}</span>
        </h3>
      </div>
      <div className='flex-1 flex gap-5 px-5 pb-4 overflow-hidden'>
        <StateQueue classBg='text-primary bg-primary/20' classBgFooter='text-primary bg-primary/20' data={dataPlasma.filter(item => item.discharge_date === null)} state="ANTRIAN KASIR" />
        <StateQueue data={dataPlasma.filter(item => item.state === 'Pending')} state="MENUNGGU" />
        {/* <StateQueue data={dataPlasma.filter(item => item.state === 'Confirmed')} state="KONFIRMASI" /> */}
        <StateQueue data={dataPlasma.filter(item => item.state === 'On Progress')} state="PROSES" />
        <StateQueue data={dataPlasma.filter(item => item.state === 'Finish')} state="SELESAI" />
        {/* <StateQueue data={dataPlasma.filter(item => item.state === 'Done')} state="DONE" /> */}
      </div>
    </div>
  )
}

export default PlasmaFarmasi