import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import ItemQueue from './ItemQueue'
import StateFooterQueue from './StateFooterQueue'

function StateQueue({ data, state, classBg = 'bg-accent/20 text-accent', classBgFooter = 'bg-accent/20 text-accent' }) {

  return (
    <div className='relative flex-1 rounded-lg bg-white shadow-lg flex flex-col overflow-hidden'>
      <div className={`sticky z-20 top-0 inset-x-0 font-bold text-2xl text-center py-3 ${classBg}`}>
        {state}
      </div>
      <ul className='flex-1 overflow-y-auto pb-10 no-scrollbar'>
        <AnimatePresence>
          {data.map((_, index) => (
            <ItemQueue key={_.Id} no_rm={_.patient_norm} queue_number={_.queue_number} insurance={_.insurance ? _.insurance : null} />
          ))}
        </AnimatePresence>
      </ul>
      <StateFooterQueue classBg={classBgFooter} labelRight={data.length} />
    </div>
  )

  return (
    <div className='relative rounded-lg bg-white shadow-lg flex-1 overflow-y-scroll scroller' data-speed='fast' data-animated={false}>

      <div className={`sticky z-10 top-0 inset-x-0 font-bold text-2xl text-center py-3 ${classBg}`}>
        {state}
      </div>
      <ul className='scroller__inner'>
        <AnimatePresence>
          {data.map((_, index) => (
            <ItemQueue key={_.Id} no_rm={_.patient_norm} queue_number={_.queue_number} insurance={_.insurance ? _.insurance : null} />
          ))}
        </AnimatePresence>
      </ul>
      <StateFooterQueue classBg={classBgFooter} labelRight={data.length} />
    </div>
  )
}

export default StateQueue