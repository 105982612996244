export function storeTokenInLocalStorage(token) {
  localStorage.setItem('token', token);
}

export function storeTokenNocoDbInLocalStorage(token) {
  localStorage.setItem('token_noco_db', token);
}

export function storeUserInLocalStorage(user) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function getTokenFromLocalStorage() {
  return localStorage.getItem('token');
}

export function getTokenNocoDbFromLocalStorage() {
  return localStorage.getItem('token_noco_db');
}

export function getUserFromLocalStorage() {
  return JSON.parse(localStorage.getItem('user'));
}

export function removeTokenFromLocalStorage() {
  localStorage.removeItem('token');
  localStorage.removeItem('token_noco_db');
  localStorage.removeItem('user');
}

export async function getAuthenticatedUser() {
  const defaultReturnObject = { authenticated: false, user: null };
  try {
    const token = getTokenFromLocalStorage();
    const user = getUserFromLocalStorage();
    if (!token || !user) {
      return defaultReturnObject;
    }

    return { authenticated: true, user };
  }
  catch (err) {
    console.log('getAuthenticatedUser, Something Went Wrong', err);
    return defaultReturnObject;
  }
}