import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function Nav({ to, label = 'Home' }) {
  const navigate = useNavigate()

  return (

    <div className='fixed z-50 inset-x-0 bg-white flex flex-col justify-center h-12 px-6'>
      {/* <Link to="/"> */}
      <button className='flex gap-2 items-center' onClick={() => navigate(to)}>
        <ArrowLeftIcon width={20} /> {label}
      </button>
      {/* </Link> */}
    </div>
  )
}

export default Nav