import React from "react";
import "./Jadwal.css";
import { motion } from "framer-motion";

function Jadwal(props) {
  const variantList = { hidden: { opacity: 0 }, show: { opacity: 1 } };
  const variantItem = {
    hidden: { x: -10, opacity: 0 },
    show: { x: 0, opacity: 1 },
  };

  const getPhotoDoc = (pid) => {
    const listDocter = props.listDocter;
    const docter = listDocter?.filter((val) => val.kode_dokter === pid);
    return docter[0]?.photo.length
      ? docter[0]?.photo
      : "photo_dokter/-2.drFerry.jpg";
  };

  const item = () => {
    const schedulePoli = props.schedulePoli;

    if (!schedulePoli) {
      return null
    }

    let groupDocByJadwal = [];

    schedulePoli.dokter.map((doc) => {
      let findIndexDoc = groupDocByJadwal.findIndex(
        (val) => val.pid === doc.pid && val.did === doc.did
      );
      if (findIndexDoc < 0) {
        groupDocByJadwal.push({
          ...doc,
          jadwal: [doc.jadwal],
        });
      } else {
        groupDocByJadwal[findIndexDoc] = {
          ...doc,
          jadwal: [...groupDocByJadwal[findIndexDoc].jadwal, doc.jadwal],
        };
      }
    });

    return (
      <div key={schedulePoli.did} className='mt-4'>
        <motion.div
          variants={variantItem}
          className='flex items-start py-2 pl-3 text-xl font-bold bg-white border-b border-gray-100 rounded-t-lg'
        >
          <svg
            stroke='currentColor'
            fill='currentColor'
            strokeWidth='0'
            viewBox='0 0 24 24'
            height='1em'
            width='1em'
            xmlns='http://www.w3.org/2000/svg'
            className='mt-1 mr-2'
          >
            <path d='M19 2H9c-1.103 0-2 .897-2 2v5.586l-4.707 4.707A1 1 0 0 0 3 16v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2zm-8 18H5v-5.586l3-3 3 3V20zm8 0h-6v-4a.999.999 0 0 0 .707-1.707L9 9.586V4h10v16z'></path>
            <path d='M11 6h2v2h-2zm4 0h2v2h-2zm0 4.031h2V12h-2zM15 14h2v2h-2zm-8 1h2v2H7z'></path>
          </svg>
          {schedulePoli.name_short}
        </motion.div>
        {groupDocByJadwal.length > 0 &&
          groupDocByJadwal.map((doc) => {
            return (
              <motion.div
                variants={variantItem}
                key={doc.pid}
                className='p-4 bg-white border-b border-gray-100'
              >
                <div className='flex items-start space-x-3'>
                  <div className='pt-1 avatar'>
                    <div className='w-12 h-12 mask mask-squircle'>
                      <img
                        src={`${process.env.REACT_APP_ASSETS}/${getPhotoDoc(
                          doc.pid
                        )}`}
                        alt={doc.name_real}
                      />
                    </div>
                  </div>
                  <div className='w-full'>
                    <div className='font-bold'>{doc.name_real}</div>

                    <div className='flex flex-wrap gap-2 mt-2'>
                      {doc.jadwal.map((x) => (
                        <div
                          key={x}
                          className='font-bold text-white badge badge-accent'
                        >
                          {x}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            );
          })}
        {groupDocByJadwal.length === 0 && (
          <motion.div
            variants={variantItem}
            className='p-4 bg-white border-b border-gray-100'
          >
            <div className='flex items-start space-x-3'>
              <div className='w-full'>
                <div className='font-bold text-center text-accent'>
                  Tidak Ada Praktek Dihari Ini
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    );
  };

  return (
    <div className='w-full'>
      {/* <div className='w-full overflow-x-auto'> */}
      {/* <table className='table w-full'> */}
      <motion.div
        initial='hidden'
        whileInView='show'
        exit='hidden'
        variants={variantList}
        transition={{ staggerChildren: 0.5 }}
        className='w-full'
      >
        {item()}
        {/* {JSON.stringify(props.schedulePoli.name_short, null, 2)} */}
      </motion.div>
      {/* </table> */}
      {/* </div> */}
    </div>
  );
}

export default Jadwal;
