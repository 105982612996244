import Loader from 'components/Loader';
import Nav from 'components/Nav';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useApiBridge } from 'services';

function ListOrder() {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getDataOrder = async () => {
    setLoading(true)
    const { data: dataWaiting } = await useApiBridge.get(`/gizi/list-order`)
    // console.log('dataWaiting', )
    setData(dataWaiting.data)
    setLoading(false)
  }

  useEffect(() => {
    getDataOrder()

    const interval = setInterval(() => {
      getDataOrder()
    }, 60000 * 10); // 10 minutes

    return () => {
      clearInterval(interval);
    };
  }, []);

  const onClickDetail = async (id) => {
    setLoading(true)
    const { data: dataWaiting } = await useApiBridge.get(`/gizi/menu/${id}`)
    console.log('dataWaiting', dataWaiting)
    // setData(dataWaiting.data)
    setLoading(false)
  }

  return (
    <>
      <Nav to={'/'} label="Gizi Order VIP" />
      <div className='max-w-7xl pt-20 pb-16'>
        <div className='px-4 flex flex-col gap-3'>
          {loading && (
            <div className='flex justify-center mt-8'><Loader /></div>
          )}
          {data.length === 0 && !loading ? <span className='text-xl font-semibold text-slate-600 text-center'>Tidak ada Pesanan!</span> : null}
          {data.length !== 0 && !loading ? (
            <>
              {data.map(item => (
                <div key={item.Id} onClick={() => onClickDetail(item.Id)} className='relative bg-white border border-slate-200 rounded-lg flex items-center px-4 py-3 cursor-pointer hover:shadow-xl hover:scale-105 transition-all'>
                  <div class="w-full">
                    <h4 class="font-semibold text-slate-800">{item.patient_name}</h4>
                    <div className='flex justify-between'>
                      <p class="text-sm opacity-70 text-slate-800">{item.room_or_floor}</p>
                      <p class="text-sm opacity-70 text-slate-800">{moment(`${item.send_date} ${item.send_time}`).format('HH:mm')}</p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default ListOrder