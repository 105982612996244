import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import DocterSchedule from "./pages/docter-schedule";
import Bed from "./pages/bed";
import PlasmaFarmasi from "./pages/plasma-farmasi";
import PlasmaFarmasiMnc from './pages/plasma-farmasi/mnc';
import UpdatePlasmaFarmasi from "./pages/plasma-farmasi/update";
import SignIn from './pages/signin';
import Home from 'pages';
import ScheduleOperasi from 'pages/operasi/schedule';

import ListOrder from 'pages/gizi/listOrder';
import UpdateVoucher from 'pages/merchant/voucher/update';
import BedJkn from 'pages/bed-jkn';
import SendInvoice from 'pages/invoice/send';

function RouterComponent() {

  const Midleware = ({ children }) => {
    return (
      <main>{children}</main>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/signin' element={<SignIn />} />
        <Route exact path='/' element={<Home />} />
        <Route exact path='/jadwal/dokter' element={<DocterSchedule />} />
        <Route exact path='/jadwal/operasi' element={<ScheduleOperasi />} />
        <Route exact path='/bed' element={<Bed />} />
        <Route exact path='/bed-jkn' element={<BedJkn />} />
        <Route exact path='/plasma-farmasi' element={<PlasmaFarmasi />} />
        <Route exact path='/plasma-farmasi/mnc' element={<PlasmaFarmasiMnc />} />
        <Route exact path='/plasma-farmasi/update' element={<Midleware><UpdatePlasmaFarmasi /></Midleware>} />

        <Route exact path='/merchant/voucher/update' element={<Midleware><UpdateVoucher /></Midleware>} />

        <Route exact path='/gizi/list-order' element={<ListOrder />} />
        <Route exact path='/invoice/send' element={<Midleware><SendInvoice /></Midleware>} />
      </Routes>
    </BrowserRouter>
  )
}

export default RouterComponent