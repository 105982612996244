export const poli_mnc = [
  108, // vaksin
  115, // vaksin dewasa
  8, // obsgyn
  105, // laktasi
  125, // spesialis gigi anak
  10, // klinik anak
  104 // tumbuh kembang
]

export const BPJS_PROSES_ID = 1235
export const BPJS_ID = 1000
export const LABORATORIUM_ID = 15
export const RANAP_ID = 5
export const IGD = 18