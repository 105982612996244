import { twMerge } from "tailwind-merge"


const ItemMenu = ({ title, description, icon, className, onClick }) => {

  return (
    <li onClick={onClick} className={`relative bg-white border border-slate-200 rounded-lg flex items-center px-4 py-3 cursor-pointer hover:shadow-xl hover:scale-105 transition-all`}>
      <div className={twMerge('flex items-center justify-center w-12 h-12 bg-primary/10 text-primary rounded-lg', className)}>
        {icon}
      </div>
      <div className='ml-4'>
        <h4 className='font-semibold text-slate-800'>{title}</h4>
        <p className='text-sm opacity-70 text-slate-800'>{description}</p>
      </div>

    </li>
  )
}

export default ItemMenu