import "./App.css";
import RouterComponent from './router'

function App() {

  const requestFullScreen = () => {
    // Supports most browsers and their versions.
    var element = document.body

    var requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen

    if (requestMethod) {
      // Native full screen.
      requestMethod.call(element)
    }
  }

  return (
    <>
      <button
        class="fixed bottom-0 left-0 text-accent pt-2 pr-2 z-50"
        onClick={requestFullScreen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      </button>
      <RouterComponent />
    </>
  );
}

export default App;
