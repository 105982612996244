import KJUR from 'jsrsasign';
import moment from 'moment';

function generateToken() {
  let oHeader = { alg: 'HS256', typ: 'JWT' };
  // Payload
  let oPayload = {};
  oPayload.iss = 'web:jadwaldok';
  let addMinute = moment().add(1, 'minutes');
  oPayload.exp = addMinute.unix();
  let sHeader = JSON.stringify(oHeader);
  let sPayload = JSON.stringify(oPayload);
  let sJWT = KJUR.jws.JWS.sign(
    'HS256',
    sHeader,
    sPayload,
    process.env.REACT_APP_TOKEN_DEFAULT
  );

  return sJWT;
}

export const get = (param) => {
  return new Promise((resolve, reject) => {
    const token = generateToken();
    const headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('access_token', token);
    headers.set('Authorization', token);
    fetch(`${process.env.REACT_APP_ENDPOINT + param}`, {
      method: 'GET',
      headers: headers,
    })
      .then((res) => res.json())
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
};

export function convertIntToRm(integer) {
  let strIdRm = integer.toString()
  let prepadZero = strIdRm.padStart(8, '0')
  let formatedIdRm = ''
  for (var i = 0; i < prepadZero.length; i++) {
    if (i === 2 || i === 4 || i === 6) {
      formatedIdRm += '-' + prepadZero[i]
    } else {
      formatedIdRm += prepadZero[i]
    }
  }
  return formatedIdRm
}
export const formatNumber = (digit) => {
  // return new Intl.NumberFormat('id-ID', {
  //   minimumFractionDigits: 0,
  // }).format(digit);
  return String(digit).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

export const formatPhoneNumber = (phone) => {
  phone = phone.toString()
  // return phone.startsWith('62') ? phone.replaceAll('-', '').replaceAll(' ', '') : phone.replace('0', '62').replaceAll('-', '').replaceAll(' ', '')
  return phone.startsWith('62') ? phone.replace(/-/g, '').replace(/\s/g, '') : phone.replace('0', '62').replace(/-/g, '').replace(/\s/g, '')
}
