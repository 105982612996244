import React, { useEffect, useReducer, useRef, useState } from 'react'
import logoEdhos from "assets/img/logo.png";
import Moment from 'react-moment';
// import QrScan from 'react-qr-reader';
import socket from 'lib/socket';
import useUser from 'hooks/useUser';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useApiBridge, useApiFlowEdelweiss, useApiNocoDb } from 'services';
import RadioButton from 'components/RadioButton';
import { ArrowLeftIcon, CheckCircleIcon, DocumentMagnifyingGlassIcon, QrCodeIcon } from '@heroicons/react/24/outline'
import { poli_mnc } from 'constants';
import { AnimatePresence, motion } from 'framer-motion';
import Nav from 'components/Nav';
import moment from 'moment';
import CheckboxCard from 'components/CheckboxCard';
import { formatNumber } from 'utils/request';

function UpdateVoucher() {
  const navigate = useNavigate();
  const { user, authenticated } = useUser();
  if (!user || !authenticated) {
    navigate('/signin?callback=/merchant/voucher/update')
  }
  const refModal = useRef();
  const [selected, setSelected] = useState('environment');
  const [startScan, setStartScan] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);

  const [dataNotFound, setDataNotFound] = useState(false);
  const [dataNocoDb, setDataNocoDb] = useState(null);
  const [dataScanedVoucher, setDataScanedVoucher] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [inputSearchNoreg, setInputSearchNoreg] = useState('');
  const [dataQR, setDataQR] = useState(null);

  const apiSearchUuid = async (uuid) => {
    console.log('uuid', uuid)

    const { data: [dataVoucher] } = await useApiFlowEdelweiss.get(`/webhook/get-voucher-uuid`, {
      params: {
        uuid
      }
    })

    console.log('dataVoucher', dataVoucher)

    return dataVoucher
  }

  const handleScan = async (scanData) => {
    try {
      document.getElementById('modal').showModal()
      setDataNotFound(false)
      // 2310110262,DD202310110154,Tidak Ada Racikan
      // no_reg, no_resep, nama_racikan

      // return;
      // if (scanData && scanData !== '' && dataQR === null) {
      if (scanData && scanData !== '') {
        setLoadingData(true);
        setDataQR(scanData);

        // const dataPlasmaFarmasi = await apiSearchNoreg(scanData.split(',')[0])
        const dataVoucher = await apiSearchUuid(scanData.split(',')[0])

        setLoadingData(false);
        setDataNocoDb(dataVoucher)
        // setDataScanedVoucher(prev => [...prev, dataVoucher])
        console.log('dataVoucher', dataVoucher)
        addItem(dataVoucher)
      }

    } catch (e) {
      console.log(e.message)
      setLoadingData(false);
      setDataNotFound(true)
      setDataNocoDb(null)
      setDataScanedVoucher([])
    }
  };

  const addItem = (newItem) => {
    setDataScanedVoucher((prevItems) => {
      if (!prevItems.some(item => item.id === newItem.id)) {
        return [newItem, ...prevItems];
      }
      return prevItems;
    });
  };

  const onSelectedVoucher = (newItem) => {
    setSelectedState((prevItems) => {
      if (!prevItems.some(item => item.id === newItem.id)) {
        return [...prevItems, newItem];
      }

      // un selected Voucher
      return prevItems.filter(item => item.id !== newItem.id);
    });
  }

  const [total, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'update':
        return action.payload.reduce((acc, item) => acc + item.amount, 0);
      default:
        return state;
    }
  }, 0);

  useEffect(() => {
    dispatch({ type: 'update', payload: selectedState });
  }, [selectedState]);

  //   let total = 0;
  //   selectedState.forEach(item => {
  //     total += item.harga_beli
  //   })
  //   return total
  // }

  const handleError = (err) => {
    console.error(err);
  };

  const updateStatus = async () => {

    try {
      setLoadingUpdateStatus(true)

      selectedState.forEach(async (item) => {
        const { data: dataUpdate } = await useApiFlowEdelweiss.patch(`/webhook/update-voucher-state`, { person_id: user.pid, state: 'used', uuid: item.code })
        console.log('dataUpdate', dataUpdate)
      })

      document.getElementById('modal').close()
      setDataQR(null)
      setInputSearchNoreg('')
      setSelectedState([])
      setDataScanedVoucher([])
    } catch (error) {
      console.log('error', error.message)
    } finally {
      setLoadingUpdateStatus(false)
    }
  };

  // const onChangeInputSearchNoreg = (e) => {
  //   if (e.key === 'Enter') {
  //     searchNoreg()
  //   } else {
  //     setInputSearchNoreg(e.target.value)
  //   }
  // }

  return (
    <div className='relative'>
      <Nav to={'/'} />
      <div className='w-full max-w-[500px] relative mx-auto flex justify-center items-center'>
        <div className='w-full pt-20 pb-16'>
          <AnimatePresence>
            {startScan && (
              <>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: "spring", stiffness: 900, damping: 40 }}
                  className='px-6 mb-4'>
                  <select
                    className='w-full mb-3 select'
                    onChange={(e) => setSelected(e.target.value)}
                  >
                    <option value={'environment'}>Back Camera</option>
                    <option value={'user'}>Front Camera</option>
                  </select>
                </motion.div>
                <motion.div
                  initial={{ translateY: -1000, scale: 0, opacity: 0 }}
                  animate={{ translateY: 0, scale: 1, opacity: 1, transition: { duration: 0.4 } }}
                  exit={{ translateY: -1000, scale: 0, opacity: 0, transition: { duration: 0.3 } }}
                  transition={{ type: "spring", stiffness: 900, damping: 40 }}
                  className='w-full px-6 overflow-hidden rounded-lg aspect-square'>
                  <QrScanner
                    constraints={{ facingMode: selected }}
                    scanDelay={300}
                    containerStyle={{ borderRadius: '0.5rem', overflow: 'hidden' }}
                    onDecode={handleScan}
                    onError={(error) => console.log(error?.message)}
                  />
                </motion.div>
              </>
            )}
          </AnimatePresence>
          {/* {loadingScan && startScan && (
            <p className='my-4 text-center'>Scaning Data</p>
          )} */}

          <div className='flex items-center justify-center mt-2 mb-8'>
            <button
              className='btn btn-outline'
              onClick={() => {
                setStartScan(!startScan);
              }}
            >
              <QrCodeIcon className='w-6 h-6' />
              {startScan ? 'Stop Scan' : 'Scan Voucher'}
            </button>
          </div>

        </div>

        <dialog id="modal" className="modal">
          <div className="modal-box">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2" onClick={() => setDataQR(null)}>✕</button>
            </form>
            <h3 className="text-lg font-bold">Data Voucher</h3>
            <p className='mt-1 mb-5 text-sm text-gray-500'>Pilih voucher yang akan di gunakan</p>
            {dataNotFound && <p className='my-4'>Data Tidak Ditemukan!</p>}
            {/* <p className='my-8'>{loadingScan ? 'Loading' : 'tidak Loading'}</p> */}
            {loadingData && (
              <div className='flex justify-center my-8'>
                <span className="loading loading-dots loading-lg text-primary"></span>
              </div>
            )}
            {dataScanedVoucher.length > 0 && (
              <div className='flex flex-col gap-4 my-4'>
                <ul className='grid w-full grid-cols-1 gap-4 form-control'>
                  {dataScanedVoucher.map(item => {
                    return (
                      <CheckboxCard
                        id={item.code}
                        label={item.meal_type}
                        description={item.state !== 'idle' ? 'Used or Expired' : `Until ${moment(item.expired_at).format('DD MMM YYYY')}`}
                        name={`voucher-${item.code}`}
                        value={item.code}
                        checked={selectedState.find(selected => selected.code === item.code)}
                        disabled={item.state !== 'idle'}
                        onChange={(e) => onSelectedVoucher(item)} />
                    )
                  })}
                </ul>
                {/* <ul className="grid w-full grid-cols-2 gap-4 form-control">
                  <RadioButton id="Pending" label="Pending" description="Sebelum masuk" name="state" value="Pending" checked={selectedState === 'Pending'} onChange={(e) => setSelectedState(e.target.value)} />
                  <RadioButton id="Confirmed" label="Confirmed" description="Sudah dikonfirmasi" name="state" value="Confirmed" checked={selectedState === 'Confirmed'} onChange={(e) => setSelectedState(e.target.value)} />
                  <RadioButton id="On Progress" label="On Progress" description="Disiapkan" name="state" value="On Progress" checked={selectedState === 'On Progress'} onChange={(e) => setSelectedState(e.target.value)} />
                  <RadioButton id="Finish" label="Finish" description="Siap diberikan" name="state" value="Finish" checked={selectedState === 'Finish'} onChange={(e) => setSelectedState(e.target.value)} />
                  <RadioButton id="Done" label="Done" description="Sudah diberikan" name="state" value="Done" checked={selectedState === 'Done'} onChange={(e) => setSelectedState(e.target.value)} />
                </ul> */}
                <div class="flex justify-between items-baseline">
                  <span className='text-lg font-semibold text-right text-gray-500'>Total Discount</span>
                  <span className='text-2xl font-semibold text-right text-primary'>-Rp {formatNumber(total)}</span>
                </div>
                <button className="text-white btn btn-accent" disabled={loadingUpdateStatus || selectedState.length === 0} onClick={updateStatus}>
                  {loadingUpdateStatus && <span className="loading loading-spinner loading-sm"></span>}
                  Pakai Voucher
                </button>
              </div>
            )}
          </div>
          <form method="dialog" className="modal-backdrop">
            <button onClick={() => setDataQR(null)}>close</button>
          </form>
        </dialog>
      </div>
    </div>
  )
}

export default UpdateVoucher