import { useIsPresent, motion } from 'framer-motion';
import React from 'react'

const ItemQueue = ({ no_rm, queue_number, insurance = null }) => {
  const isPresent = useIsPresent();
  const animations = {
    style: {
      position: isPresent ? "static" : "absolute"
    },
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1, transition: { duration: 0.4 } },
    exit: { scale: 0, opacity: 0, transition: { duration: 0.3 } },
    transition: { type: "spring", stiffness: 900, damping: 40 }
  };
  return (
    <motion.li {...animations} layout className='py-4 px-3 border-b border-gray-200 last:border-b-0 flex items-center justify-between w-full'>
      <div className='flex-1'>
        <div className='flex items-end space-x-2'>
          <h5 className='font-semibold text-gray-400 text-sm'>No. RM </h5>
          {insurance ? <div className='rounded bg-red-500 text-white text-xs px-2 mb-1'>Asuransi</div> : null}
        </div>
        <h1 className='text-gray-600 text-3xl'>{no_rm}</h1>
      </div>
      {queue_number ? <div className='border-2 border-primary text-gray-600 font-bold rounded-lg text-2xl px-3 py-1'>{queue_number}</div> : null}
    </motion.li>
  )
}

export default ItemQueue