import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useApiBridge, useApiNocoDb } from 'services'
import { useNavigate, useNavigation, useParams, useRoutes, useSearchParams } from 'react-router-dom'
import useUser from 'hooks/useUser'
import { storeTokenInLocalStorage, storeTokenNocoDbInLocalStorage, storeUserInLocalStorage } from 'lib/common'
import Nav from 'components/Nav'

function SignIn() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   username: 'ilman.qori',
    //   password: 'edelweiss123'
    // }
  })
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [errorsRequest, setErrorRequest] = useState('')
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get("callback");
  const { user, authenticated } = useUser();

  if (user || authenticated) {
    navigate('/')
  }


  const onSubmit = async (data) => {
    try {
      setLoading(true)
      // await new Promise((resolve) => setTimeout(resolve, 2000))
      const { data: response } = await useApiBridge.post('/auth', data)

      storeTokenInLocalStorage(response.token);
      storeUserInLocalStorage(response.person);

      if (callbackUrl) {
        navigate(callbackUrl)
      } else {
        navigate("/")
      }
    } catch (error) {
      if (error.response.status === 404) setErrorRequest('Username atau Password salah!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>

      <Nav to={'/'} />
      <div className='h-screen flex justify-center items-center'>
        <form className='w-full max-w-xs' onSubmit={handleSubmit(onSubmit)}>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Username</span>
              {/* <span className="label-text-alt">(Akun tera)</span> */}
            </label>
            <input type="text" placeholder="Type here" className="input input-bordered w-full" {...register("username", { required: true })} readOnly={false} />
            <label className='label'>
              {errors.username && <span className='label-text-alt text-red-600'>This field is required!</span>}
              {errorsRequest && <span className='label-text-alt text-red-600'>{errorsRequest}</span>}
            </label>
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">Password</span>
            </label>
            <input type="password" placeholder="Type here" className="input input-bordered w-full"  {...register("password", { required: true })} readOnly={false} />
            <label className='label'>
              {errors.password && <span className='label-text-alt text-red-600'>This field is required!</span>}
            </label>
          </div>
          <button type='submit' disabled={loading ? true : false} className="btn btn-accent btn-block mt-4 ">
            {loading && <span className="loading loading-spinner"></span>}
            {loading ? 'loading' : 'Sign In'}
          </button>
          {/* <div className="divider">OR</div>
          <button type='button' onClick={() => navigate('/')} className='btn btn-outline btn-block'>Kembali ke Menu</button> */}
        </form>
      </div>
    </>
  )
}

export default SignIn