import React, { useEffect, useRef, useState } from 'react'
import logoEdhos from "assets/img/logo.png";
import Moment from 'react-moment';
// import QrScan from 'react-qr-reader';
import socket from 'lib/socket';
import useUser from 'hooks/useUser';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useApiBridge, useApiNocoDb } from 'services';
import RadioButton from 'components/RadioButton';
import { ArrowLeftIcon, CheckCircleIcon, DocumentMagnifyingGlassIcon, QrCodeIcon } from '@heroicons/react/24/outline'
import { poli_mnc } from 'constants';
import { AnimatePresence, motion } from 'framer-motion';
import Nav from 'components/Nav';

function UpdatePlasmaFarmasi() {
  const navigate = useNavigate();
  const { user, authenticated } = useUser();
  if (!user || !authenticated) {
    navigate('/signin?callback=/plasma-farmasi/update')
  }
  const refModal = useRef();
  const [searchParams] = useSearchParams();
  const updateToState = searchParams.get("update-to-state");
  const [selected, setSelected] = useState('environment');
  const [startScan, setStartScan] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);

  const [dataNotFound, setDataNotFound] = useState(false);
  const [dataNocoDb, setDataNocoDb] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [inputSearchNoreg, setInputSearchNoreg] = useState('');
  const [dataQR, setDataQR] = useState(null);


  const apiSearchNoreg = async (noreg) => {
    const { data: { data: dataRegistration } } = await useApiBridge.get(`/get-registration?noreg=${noreg}`)

    const { data: { data: dataPlasmaFarmasi } } = await useApiBridge.get(`/farmasi/regpid/${dataRegistration.regpid}`)

    console.log('dataPlasmaFarmasi', dataPlasmaFarmasi)

    // const { data: dataPlasmaFarmasi } = await useApiNocoDb.get(`/Support Edelweiss/Plasma Farmasi?where=(regpid,eq,${dataRegistration.regpid})`)

    if (dataPlasmaFarmasi.length === 0) throw new Error('NocodeDB data not found')

    return dataPlasmaFarmasi[0]
  }

  const handleScan = async (scanData) => {
    try {
      document.getElementById('modal').showModal()
      setDataNotFound(false)
      // 2310110262,DD202310110154,Tidak Ada Racikan
      // no_reg, no_resep, nama_racikan

      // return;
      if (scanData && scanData !== '' && dataQR === null) {
        setLoadingData(true);
        setDataQR(scanData);

        const dataPlasmaFarmasi = await apiSearchNoreg(scanData.split(',')[0])

        setLoadingData(false);
        setDataNocoDb(dataPlasmaFarmasi)
      }

    } catch (e) {
      console.log(e.message)
      setLoadingData(false);
      setDataNotFound(true)
      setDataNocoDb(null)
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const updateStatus = async (paramRegpid, paramState) => {
    console.log(`/farmasi/update-all/${paramRegpid}`)
    if (!paramRegpid) paramRegpid = dataNocoDb.regpid
    if (!paramState) paramState = selectedState

    const payload = {
      state: paramState,
      updated_pid: user.pid
    }

    try {
      setLoadingUpdateStatus(true)
      // const { data: dataUpdate } = await useApiNocoDb.patch(`/Support Edelweiss/Plasma Farmasi/${dataNocoDb.Id}`, payload)
      // const { data: dataUpdate } = await useApiBridge.patch(`/farmasi/update/${dataNocoDb.Id}`, payload)
      const { data: dataUpdate } = await useApiBridge.patch(`/farmasi/update-all/${paramRegpid}`, payload)

      document.getElementById('modal').close()
      setDataQR(null)
      setInputSearchNoreg('')

      let modulePlasma = ''
      const plasma_executive = 'plasma/farmasi'
      const plasma_mnc = 'plasma/farmasi/mnc'

      if (poli_mnc.includes(+dataUpdate.poli_id)) {
        modulePlasma = plasma_mnc
      } else {
        modulePlasma = plasma_executive
      }

      const dataSocket = JSON.stringify({
        module: modulePlasma,
        payload: dataUpdate
      })

      socket.emit('send_messages', dataSocket);
    } catch (error) {
      console.log('error', error.message)
    } finally {
      setLoadingUpdateStatus(false)
      setSelectedState('')
    }
  };

  // const onChangeInputSearchNoreg = (e) => {
  //   if (e.key === 'Enter') {
  //     searchNoreg()
  //   } else {
  //     setInputSearchNoreg(e.target.value)
  //   }
  // }

  const searchNoreg = async () => {
    console.log('inputSearchNoreg', inputSearchNoreg)
    let inputNoreg = inputSearchNoreg

    if (!updateToState) {
      // show modal if update-to-state not found (manual scan)
      document.getElementById('modal').showModal()
    } else {
      // update state immediately if update-to-state found 
      setSelectedState(updateToState)
      inputNoreg = inputSearchNoreg.split(',')[0]
    }

    try {
      setDataNotFound(false)
      setLoadingData(true);

      console.log('inputSearchNoreg update', inputNoreg)
      const dataPlasmaFarmasi = await apiSearchNoreg(inputNoreg)
      setDataNocoDb(dataPlasmaFarmasi)
      // update state immediately
      if (updateToState) {
        updateStatus(dataPlasmaFarmasi.regpid, updateToState)
      }
    } catch (e) {
      console.log(e.message)
      setDataNotFound(true)
      setDataNocoDb(null)
    } finally {
      setLoadingData(false);
    }
  }

  return (
    <div className='relative'>
      <Nav to={'/'} />
      <div className='w-full max-w-[500px] relative mx-auto flex justify-center items-center'>
        <div className='w-full pt-20 pb-16'>

          <form onSubmit={e => {
            e.preventDefault()
            searchNoreg()
          }}>
            {/* <div className='join'> */}
            <div className="w-full px-6 form-control">
              <label className="label">
                <span className="label-text">No. Registrasi</span>
              </label>
              <div className='join'>
                <input type="tel" placeholder="Noreg" className="w-full input input-bordered join-item" value={inputSearchNoreg} onChange={e => setInputSearchNoreg(e.target.value)}
                />
                <button className="rounded-r-full btn btn-accent join-item"
                  type='submit'
                  disabled={inputSearchNoreg === ''}>
                  {updateToState ? (
                    <h2 className="text-sm font-bold">
                      Update to {updateToState}
                    </h2>
                  ) : 'Cari'}
                  {/* <DocumentMagnifyingGlassIcon className='w-6 h-6' /> */}
                </button>
              </div>
            </div>
            {/* </div> */}
          </form>

          <div className="px-6 divider">OR</div>

          <AnimatePresence>
            {startScan && (
              <>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: "spring", stiffness: 900, damping: 40 }}
                  className='px-6 mb-4'>
                  <select
                    className='w-full mb-3 select'
                    onChange={(e) => setSelected(e.target.value)}
                  >
                    <option value={'environment'}>Back Camera</option>
                    <option value={'user'}>Front Camera</option>
                  </select>
                </motion.div>
                <motion.div
                  initial={{ translateY: -1000, scale: 0, opacity: 0 }}
                  animate={{ translateY: 0, scale: 1, opacity: 1, transition: { duration: 0.4 } }}
                  exit={{ translateY: -1000, scale: 0, opacity: 0, transition: { duration: 0.3 } }}
                  transition={{ type: "spring", stiffness: 900, damping: 40 }}
                  className='w-full px-6 overflow-hidden rounded-lg aspect-square'>
                  <QrScanner
                    constraints={{ facingMode: selected }}
                    scanDelay={300}
                    containerStyle={{ borderRadius: '0.5rem', overflow: 'hidden' }}
                    onDecode={handleScan}
                    onError={(error) => console.log(error?.message)}
                  />
                </motion.div>
              </>
            )}
          </AnimatePresence>
          {/* {loadingScan && startScan && (
            <p className='my-4 text-center'>Scaning Data</p>
          )} */}

          <div className='flex items-center justify-center mt-4 mb-8'>
            <button
              className='btn btn-outline'
              onClick={() => {
                setStartScan(!startScan);
              }}
            >
              <QrCodeIcon className='w-6 h-6' />
              {startScan ? 'Stop Scan' : 'Start Scan'}
            </button>
          </div>
        </div>

        <dialog id="modal" className="modal">
          <div className="modal-box">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2" onClick={() => setDataQR(null)}>✕</button>
            </form>
            <h3 className="text-lg font-bold">Data Registrasi</h3>
            {dataNotFound && <p className='my-4'>Data Tidak Ditemukan!</p>}
            {/* <p className='my-8'>{loadingScan ? 'Loading' : 'tidak Loading'}</p> */}
            {loadingData && (
              <div className='flex justify-center my-8'>
                <span className="loading loading-dots loading-lg text-primary"></span>
              </div>
            )}
            {!loadingData && dataNocoDb && (
              <div className='flex flex-col gap-4 my-4'>
                <div className="mb-6 border stats bg-primary/10 border-primary/30">

                  <div className="w-full stat">
                    <div className="stat-title ">
                      <Moment
                        format='YYYY-MM-DD'
                        locale='id'
                      >
                        {dataNocoDb.patient_dob}
                      </Moment>
                    </div>
                    <div className="flex-wrap text-lg text-gray-700 whitespace-pre-line stat-value">{dataNocoDb.patient_name}</div>
                    <div className="stat-actions">
                      <button className="badge badge-accent">
                        {dataNocoDb.state}
                      </button>
                    </div>
                  </div>

                </div>
                {/* <div className="w-full form-control">
                  <label className="label">
                    <span className="label-text">Status Obat</span>
                  </label>
                  <select className="select select-bordered" onChange={(e) => setSelectedState(e.target.value)}>
                    <option value=''>Silahkan Pilih</option>
                    <option value="Pending">Pending</option>
                    <option value="Confirmed">Confirmed</option>
                    <option value="On Progress">On Progress</option>
                    <option value="Finish">Finish</option>
                    <option value="Done">Done</option>
                  </select>
                </div> */}
                <ul className="grid w-full grid-cols-2 gap-4 form-control">
                  <RadioButton id="Pending" label="Pending" description="Sebelum masuk" name="state" value="Pending" checked={selectedState === 'Pending'} onChange={(e) => setSelectedState(e.target.value)} />
                  <RadioButton id="Confirmed" label="Confirmed" description="Sudah dikonfirmasi" name="state" value="Confirmed" checked={selectedState === 'Confirmed'} onChange={(e) => setSelectedState(e.target.value)} />
                  <RadioButton id="On Progress" label="On Progress" description="Disiapkan" name="state" value="On Progress" checked={selectedState === 'On Progress'} onChange={(e) => setSelectedState(e.target.value)} />
                  <RadioButton id="Finish" label="Finish" description="Siap diberikan" name="state" value="Finish" checked={selectedState === 'Finish'} onChange={(e) => setSelectedState(e.target.value)} />
                  <RadioButton id="Done" label="Done" description="Sudah diberikan" name="state" value="Done" checked={selectedState === 'Done'} onChange={(e) => setSelectedState(e.target.value)} />
                </ul>
                <button className="btn btn-accent" disabled={loadingUpdateStatus || !selectedState} onClick={() => updateStatus(dataNocoDb.regpid, selectedState)}>
                  {loadingUpdateStatus && <span className="loading loading-spinner loading-sm"></span>}
                  Simpan
                </button>
              </div>
            )}
          </div>
          <form method="dialog" className="modal-backdrop">
            <button onClick={() => setDataQR(null)}>close</button>
          </form>
        </dialog>
      </div>
    </div>
  )
}

export default UpdatePlasmaFarmasi