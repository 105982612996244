import axios from 'axios';
import { getTokenFromLocalStorage, getTokenNocoDbFromLocalStorage, removeTokenFromLocalStorage } from 'lib/common';
import moment from 'moment';

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT, // Set your API base URL here
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getTokenFromLocalStorage()
    if (token) {
      config.headers['authorization'] = token;
    } else {
      delete config.headers['authorization'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect user to login page or display a message for token expiry
      removeTokenFromLocalStorage();

      window.location.href = '/signin';
    }
    return Promise.reject(error);
  }
);

export const useApiBridge = {
  get: (url, config) => {
    return instance.get(url, config)
  },
  post: (url, data, config) => {
    return instance.post(url, data, config)
  },
  put: (url, data, config) => {
    return instance.put(url, data, config)
  },
  patch: (url, data, config) => {
    return instance.patch(url, data, config)
  },
  delete: (url, config) => {
    return instance.delete(url, config)
  },
};


// nocode db
const instanceNocoDb = axios.create({
  baseURL: process.env.REACT_APP_NOCODE_DB_BASE, // Set your API base URL here
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

instanceNocoDb.interceptors.request.use(
  (config) => {
    const token = getTokenNocoDbFromLocalStorage()
    if (token) {
      config.headers['xc-auth'] = token;
      config.baseURL = process.env.REACT_APP_NOCODE_DB_BASE + process.env.REACT_APP_NOCODE_DB_PATH_DATA;
    } else {
      config.baseURL = process.env.REACT_APP_NOCODE_DB_BASE
      // delete config.headers['xc-auth'];
    }

    console.log('config.baseURL', config.baseURL)

    return config;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect user to login page or display a message for token expiry
      removeTokenFromLocalStorage();

      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
instanceNocoDb.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect user to login page or display a message for token expiry
      removeTokenFromLocalStorage();

      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export const useApiNocoDb = {
  get: (url, config) => {
    return instanceNocoDb.get(url, config)
  },
  post: (url, data, config) => {
    return instanceNocoDb.post(url, data, config)
  },
  put: (url, data, config) => {
    return instanceNocoDb.put(url, data, config)
  },
  patch: (url, data, config) => {
    return instanceNocoDb.patch(url, data, config)
  },
  delete: (url, config) => {
    return instanceNocoDb.delete(url, config)
  },
};


const instanceFlowEdelweiss = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_FLOW_EDELWEISS, // Set your API base URL here
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

instanceFlowEdelweiss.interceptors.request.use(
  (config) => {
    const token = getTokenFromLocalStorage()
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    } else {
      delete config.headers['authorization'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
instanceFlowEdelweiss.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect user to login page or display a message for token expiry
      removeTokenFromLocalStorage();

      window.location.href = '/signin';
    }
    return Promise.reject(error);
  }
);

export const useApiFlowEdelweiss = {
  get: (url, config) => {
    return instanceFlowEdelweiss.get(url, config)
  },
  post: (url, data, config) => {
    return instanceFlowEdelweiss.post(url, data, config)
  },
  put: (url, data, config) => {
    return instanceFlowEdelweiss.put(url, data, config)
  },
  patch: (url, data, config) => {
    return instanceFlowEdelweiss.patch(url, data, config)
  },
  delete: (url, config) => {
    return instanceFlowEdelweiss.delete(url, config)
  },
};