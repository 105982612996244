import React from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

function RadioButton(props) {
  return (
    <li className='relative'>
      <input type="radio" className="hidden peer" {...props} />
      <label htmlFor={props.id} className={'px-5 py-3 block bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primary peer-checked:ring-1 peer-checked:border-transparent ' + props.className}>
        <h4 className='font-semibold'>{props.label}</h4>
        {props.description && <p className='text-sm opacity-70'>{props.description}</p>}
      </label>
      <div className='absolute hidden peer-checked:flex inset-y-0 right-3 flex-col justify-center'>
        <CheckCircleIcon className='w-6 h-6 text-primary' />
      </div>
    </li>
  )
}

export default RadioButton