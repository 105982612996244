import React from "react";

function CardBed({ title, count, label, countClass }) {
  return (
    <div className='relative rounded-lg bg-white shadow-lg flex-1 h-[20vh] flex justify-center items-center overflow-hidden'>
      <div
        className={`absolute top-0 left-0 text-white text-[1.8vh] font-bold tracking-[0.15vh] bg-accent rounded-br-lg px-[1.5vh] py-[0.5vh]`}
      >
        {title}
      </div>
      <div className='text-center'>
        <div
          className={`text-[10vh] text-gray-700 font-extrabold leading-[12vh] ${countClass}`}
        >
          {count}
        </div>
        {label && (
          <div className='text-[2vh] text-gray-700/80 font-bold'>
            {label} Bed
          </div>
        )}
      </div>
    </div>
  );
}

export default CardBed;
