import React, { useEffect, useRef, useState } from 'react'
import logoEdhos from "assets/img/logo.png";
import Moment from 'react-moment';
// import QrScan from 'react-qr-reader';
import socket from 'lib/socket';
import useUser from 'hooks/useUser';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useApiBridge, useApiNocoDb } from 'services';
import RadioButton from 'components/RadioButton';
import { ArrowLeftIcon, CheckCircleIcon, DocumentMagnifyingGlassIcon, QrCodeIcon } from '@heroicons/react/24/outline'
import { poli_mnc } from 'constants';
import { AnimatePresence, motion } from 'framer-motion';
import Nav from 'components/Nav';
import { BPJS_ID } from 'constants';
import { RANAP_ID } from 'constants';
import { LABORATORIUM_ID } from 'constants';
import { IGD } from 'constants';
import axios from 'axios';
import { formatPhoneNumber } from 'utils/request';
import { BPJS_PROSES_ID } from 'constants';

function SendInvoice() {
  const navigate = useNavigate();
  const { user, authenticated } = useUser();
  if (!user || !authenticated) {
    navigate('/signin?callback=/invoice/send')
  }
  const refModal = useRef();
  const [selected, setSelected] = useState('environment');
  const [startScan, setStartScan] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false);

  const [dataNotFound, setDataNotFound] = useState(false);
  const [dataNocoDb, setDataNocoDb] = useState(null);
  const [selectedState, setSelectedState] = useState('');
  const [inputSearchNoreg, setInputSearchNoreg] = useState('');
  const [inputWhatsappNumber, setInputWhatsappNumber] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [dataQR, setDataQR] = useState(null);


  const apiSearchNoreg = async (noreg) => {
    const { data: { data: dataRegistration } } = await useApiBridge.get(`/get-registration?noreg=${noreg}`)
    console.log('2404040233', dataRegistration)
    return dataRegistration
  }

  const handleError = (err) => {
    console.error(err);
  };

  const sendInvoice = async () => {
    try {
      setLoadingUpdateStatus(true)
      const url = `https://flow.edelweiss.id/webhook/send-whatsapp/bill`
      const res = await axios.post(url, {
        noreg: dataNocoDb.noreg,
        patient_number: formatPhoneNumber(inputWhatsappNumber),
      })
    } catch (error) {
      console.log('error', error.message)
    } finally {
      setLoadingUpdateStatus(false)
      setInputWhatsappNumber('')
      setInputSearchNoreg('')
      document.getElementById('modal').close()
    }
  }

  // const onChangeInputSearchNoreg = (e) => {
  //   if (e.key === 'Enter') {
  //     searchNoreg()
  //   } else {
  //     setInputSearchNoreg(e.target.value)
  //   }
  // }

  const searchNoreg = async () => {
    document.getElementById('modal').showModal()

    try {
      setDataNotFound(false)
      setLoadingData(true);

      const dataRegpatient = await apiSearchNoreg(inputSearchNoreg)
      setDataNocoDb(dataRegpatient)
      setInputWhatsappNumber(dataRegpatient.phone_number)
      setInputEmail(dataRegpatient.email)
      setLoadingData(false);
    } catch (e) {
      console.log(e.message)
      setLoadingData(false);
      setDataNotFound(true)
      setDataNocoDb(null)
    }
  }

  return (
    <div className='relative'>
      <Nav to={'/'} label='Invoice Send To Customer' />
      <div className='w-full max-w-[500px] relative mx-auto flex justify-center items-center'>
        <div className='w-full pt-20 pb-16'>

          <form onSubmit={e => {
            e.preventDefault()
            searchNoreg()
          }}>
            {/* <div className='join'> */}
            <div className="w-full px-6 form-control">
              <label className="label">
                <span className="label-text">No. Registrasi</span>
              </label>
              <div className='join'>
                <input type="tel" placeholder="Noreg" className="w-full input input-bordered join-item" value={inputSearchNoreg} onChange={e => setInputSearchNoreg(e.target.value)}
                />
                <button className="rounded-r-full btn btn-accent join-item"
                  type='submit'
                  disabled={inputSearchNoreg === ''}>
                  Cari
                  <DocumentMagnifyingGlassIcon className='w-6 h-6' />
                </button>
              </div>
            </div>
            {/* </div> */}
          </form>
        </div>

        <dialog id="modal" className="modal">
          <div className="modal-box">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2" onClick={() => setDataQR(null)}>✕</button>
            </form>
            <h3 className="text-lg font-bold">Data Registrasi</h3>
            {dataNotFound && <p className='my-4'>Data Tidak Ditemukan!</p>}
            {/* <p className='my-8'>{loadingScan ? 'Loading' : 'tidak Loading'}</p> */}
            {loadingData && (
              <div className='flex justify-center my-8'>
                <span className="loading loading-dots loading-lg text-primary"></span>
              </div>
            )}
            {!loadingData && dataNocoDb && (
              <div className='flex flex-col gap-4 my-4'>
                <div className="border stats border-primary/30">

                  <div className="w-full stat">
                    <div className="stat-title ">
                      <Moment
                        format='YYYY-MM-DD'
                        locale='id'
                      >
                        {dataNocoDb.patient_dob}
                      </Moment>
                    </div>
                    <div className="flex-wrap text-lg text-gray-700 whitespace-pre-line stat-value">{dataNocoDb.patient_name}</div>
                    <div className="stat-actions">

                      <div className="stat-title ">
                        {dataNocoDb.poli_name}
                      </div>
                      <button className="badge badge-accent">
                        {dataNocoDb.docter_name}
                      </button>
                    </div>
                  </div>

                </div>

                {/* {dataNocoDb.ifirm_id !== BPJS_ID && dataNocoDb.poli_id !== RANAP_ID && dataNocoDb.poli_id !== LABORATORIUM_ID && dataNocoDb.poli_id !== IGD && dataNocoDb.ifirm_id !== BPJS_PROSES_ID ? ( */}
                {dataNocoDb.ifirm_id !== BPJS_ID && dataNocoDb.ifirm_id !== BPJS_PROSES_ID ? (
                  <>
                    <div className="w-full -mb-2 form-control">
                      <label className="label">
                        <span className="label-text">No. WhatsApp</span>
                      </label>
                      <input type="tel" placeholder="08129xxxx" className="w-full input input-bordered" value={inputWhatsappNumber} onChange={e => setInputWhatsappNumber(e.target.value)}
                      />
                      <div className="label">
                        <i className="label-text-alt opacity-70">Invoice akan dikirimkan ke nomor ini</i>
                      </div>
                    </div>
                    {/* <div className="w-full form-control">
                      <label className="label">
                        <span className="label-text">Email</span>
                        <i className="label-text-alt opacity-70">Opsional</i>
                      </label>
                      <input type="email" placeholder="email" className="w-full input input-bordered" value={inputEmail} onChange={e => setInputEmail(e.target.value)}
                      />
                    </div> */}
                    <button className="btn btn-accent" disabled={loadingUpdateStatus || inputWhatsappNumber === ''} onClick={sendInvoice}>
                      {loadingUpdateStatus && <span className="loading loading-spinner loading-sm"></span>}
                      Kirim Invoice
                    </button>
                  </>
                ) : (
                  <>
                    <i className="text-red-600 label-text-alt">Tidak dapat mengirim Invoice! dikarenakan kemungkinan pasien termasuk kedalam:</i>
                    <ul className='-mt-2'>
                      <li><i className="opacity-70 label-text-alt">- Pasien adalah pasien BPJS</i></li>
                      {/* <li><i className="opacity-70 label-text-alt">- Pasien adalah dari RANAP</i></li>
                      <li><i className="opacity-70 label-text-alt">- Pasien adalah dari LAB</i></li>
                      <li><i className="opacity-70 label-text-alt">- Pasien adalah dari IGD</i></li> */}
                    </ul>
                  </>
                )}

              </div>
            )}
          </div>
          <form method="dialog" className="modal-backdrop">
            <button onClick={() => setDataQR(null)}>close</button>
          </form>
        </dialog>
      </div>
    </div>
  )
}

export default SendInvoice