import { getByTestId } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment/locale/id";
import logoEdhos from "assets/img/logo.png";
import CardBed from "components/CardBed";
import { convertIntToRm, get } from "utils/request";
import { useNavigate } from "react-router-dom";

function ScheduleOperasi() {
  const navigate = useNavigate()
  const [dayOfWeekIso, setDayOfWeekIso] = useState(new Date().getDay());
  const [currentDateTime, setCurrentDateTime] = useState(
    new Date().toISOString()
  );

  const [patientOperasi, setPatientOperasi] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      initPatientOperasi();
    }, 60000 * 5); // 5 minutes

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    initPatientOperasi();
  }, [dayOfWeekIso]);

  const initPatientOperasi = async () => {

    const { data: dataOperasi } = await get(`/get-dashboard-operasi`)

    setPatientOperasi(dataOperasi);
  };

  const isChangeDaysOfWeekIso = (newValue) => {
    setDayOfWeekIso((prev) => {
      if (prev !== newValue) {
        // getDataJadwal(newValue);
        return newValue;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className='relative h-screen'>
      <img
        src={logoEdhos}
        alt='Logo'
        className='w-[15vh] cursor-pointer absolute top-4 left-4'
        onClick={() => { navigate('/') }}
      />

      <h1 className='text-center font-extrabold text-[4vh] text-gradient uppercase'>
        Jadwal Operasi (hari ini)
      </h1>
      <h3 className='text-center font-extrabold text-[2.5vh] text-gradient'>
        <Moment
          interval={1000}
          format='dddd, DD MMM HH:mm:ss'
          locale='id'
          onChange={(val) => setCurrentDateTime(new Date().toISOString())}
        >
          {currentDateTime}
        </Moment>{" "}
        WIB
        <Moment
          className='hidden'
          interval={1000}
          format='E'
          // format='dddd, DD MMM HH:mm:ss'
          locale='id'
          onChange={(val) => isChangeDaysOfWeekIso(Number(val))}
        >
          {new Date().toISOString()}
        </Moment>
      </h3>

      {patientOperasi.length === 0 && (
        <div className="h-full flex justify-center items-center font-bold text-3xl text-gray-400"> Belum ada jadwal Operasi hari ini!</div>
      )}

      <div className="grid grid-cols-4 gap-4 px-4 mt-4">
        {patientOperasi.map(item => {
          return (
            <div key={item.pid} className="shadow bg-white px-4 py-3 rounded-lg">
              <h3 className="text-2xl text-accent font-semibold mb-3">{convertIntToRm(item.pid)}</h3>
              <Moment
                interval={1000}
                format='HH:mm [WIB]'
                locale='id'
                className="text-md  text-gray-700/70"
              >
                {item.tgl_operasi}
              </Moment>
              <p className="text-md  text-gray-700/90">{item.paket_operasi}</p>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default ScheduleOperasi;
