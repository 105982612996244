import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'

function ClockFullTime() {
  const [currentDateTime, setCurrentDateTime] = useState(
    new Date().toISOString()
  );
  const [dayOfWeekIso, setDayOfWeekIso] = useState(new Date().getDay());

  const isChangeDaysOfWeekIso = (newValue) => {
    setDayOfWeekIso((prev) => {
      if (prev !== newValue) {
        return newValue;
      } else {
        return prev;
      }
    });
  };

  useEffect(() => {
    // initBed();
  }, [dayOfWeekIso]);
  return (
    <>
      <Moment
        interval={1000}
        format='dddd, DD MMM HH:mm:ss'
        locale='id'
        onChange={(val) => setCurrentDateTime(new Date().toISOString())}
      >
        {currentDateTime}
      </Moment>{" "}
      WIB
      <Moment
        className='hidden'
        interval={1000}
        format='E'
        // format='dddd, DD MMM HH:mm:ss'
        locale='id'
        onChange={(val) => isChangeDaysOfWeekIso(Number(val))}
      >
        {new Date().toISOString()}
      </Moment>
    </>
  )
}

export default ClockFullTime